<template>
  <div
    class="collapse menu-collapse"
    id="collapseCountries"
    data-parent="#accordion"
  >
    <div class="menu-accordion p-2">
      <div class="row">
        <div class="col-6">
          <div @click="setCategory('')">
            <div class="item-wrapper">
              <img v-bind:src="getCountryFlag('')" width="13" height="13"  alt="Flags"/>
              <span class="ml-2 item-wrapper-span-active">All Countries </span>
            </div>
          </div>
        </div>


<!--        <div class="text-white">{{ countries }}</div>-->
        <div class="col-6" v-for="(c, index) in countries" :key="getCountryKey(c, index)">
          <div @click="setCategory(c)">
            <div class="item-wrapper">
              <img :src="getCountryFlag(c.country_code)" width="13" height="13" alt="country flag"/>
              <span class="ml-2 item-wrapper-span-active text-white">{{ c.country_name }} ({{ c.games }})</span>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
// import axios from "../../services/api";
import fix from "@/services/fix";

export default {
  name: "countries",
  data: function () {
    return {
      countries: [],
    };
  },
  mounted() {
    this.getCountries();
  },
  computed: {
    category_id: function () {
      return this.$store.state.category_id;
    },
    
  },
  methods: {
    getCountries: function () {
      var vm = this;

      var path = process.env.VUE_APP_BASE_FIXTURE_URL + "/categories";

      fix
          .get(path)
          .then((res) => {
            vm.countries = res.data.map((item) => ({
              category_id: item.category_id,
              country_code: item.country_code,
              country_name: item.country_name,
              games: item.games
            })).slice(0, 25);
          })
          .catch((error) => {
            console.log(error);
          });
    },

    getCountryKey: function (league, index) {
      var prefix =
        league === undefined || league.sport_id == undefined
          ? index
          : league.sport_id;
      return Math.random()
        .toString(10)
        .replace("0.", "sport-id-" + prefix + "-");
    },

    setCategory: function (country) {
      document.getElementById("countries-btn").click();
      this.menu = "country";

      if (country === "") {
        this.$store.dispatch("setCategoryID", "");
      } else {
        this.$store.dispatch("setCategoryID", country.category_id);
      }
    },
    
  },
};
</script>

<style scoped></style>
